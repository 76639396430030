<template>
    <div class="select-none">
        <h1 class="font-semibold text-xl">topify</h1>
        <h2 class="text-xs">by saku</h2>
    </div>
</template>

<script>
export default {
    name:"NavBarTitle"
}
</script>